<template>
    <div class="col-12 caixa-cronometro">
        <div class="label-tempo text-light">Tempo concedido</div>
        <div
            class="relogio"
            :class="{ even: segundos % 2 === 0, acabando: !isNegativo && isAcabando, negativo: isNegativo }"
        >
            <span v-show="horas > 0">{{ horas }}</span>
            <span v-show="horas > 0" class="separador">:</span>
            <span>{{ String(minutos).padStart(2, '0') }}</span>
            <span class="separador">:</span>
            <span>{{ String(segundos).padStart(2, '0') }}</span>
        </div>
    </div>
</template>

<script>
const audioBeepShort = new Audio(require('@/assets/audio/beep-short.wav'));
const audioBeepLong = new Audio(require('@/assets/audio/beep-long.wav'));

export default {
    props: {
        horas: {
            type: Number,
            required: true
        },
        minutos: {
            required: true
        },
        segundos: {
            required: true
        },
        isNegativo: {
            type: Boolean,
            required: true
        },
        alignment: {
            type: String,
            required: false
        }
    },

    computed: {
        isAcabando() {
            return this.horas == 0 && this.minutos == 0 && this.segundos <= 10 && (!this.isNegativo || this.segundos == 0);
        }
    },

    methods: {
        playShortBeep() {
            audioBeepShort.pause();
            audioBeepShort.currentTime = 0;
            audioBeepShort.play();
        },
        playLongBeep() {
            audioBeepLong.pause();
            audioBeepLong.currentTime = 0;
            audioBeepLong.play();
        }
    },

    watch: {
        segundos(value) {
            if (this.isAcabando) {
                if (value == 0) {
                    this.playLongBeep();
                } else {
                    this.playShortBeep();
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
.caixa-cronometro {
    font-size: 1em;
    @media (min-width: 640px) { font-size: 1.2em; }
    @media (min-width: 768px) { font-size: 1.5em; }
    @media (min-width: 1024px) { font-size: 1.8em; }
    @media (min-width: 1280px) { font-size: 2.1em; }
    @media (min-width: 1536px) { font-size: 2.5em; }
    @media (min-width: 2000px) { font-size: 2.8em; }
    @media (min-width: 2400px) { font-size: 3.1em; }
    @media (min-width: 2800px) { font-size: 3.4em; }
    @media (min-width: 3200px) { font-size: 3.7em; }
    @media (min-width: 3600px) { font-size: 4.1em; }
    @media (min-width: 4000px) { font-size: 4.5em; }
}

.relogio {
    font-size: 1em;
    font-weight: bold;
    color: #f6fa35;

    &.acabando {
        color: #e74a3b;
    }

    &.negativo {
        color: #e74a3b;
        text-shadow: 0 0 0.16em rgba(255, 0, 0, 0.8);
    }

    & .separador {
        font-weight: normal;
        transition: all 0.5s ease-in-out;
    }

    &.even .separador {
        opacity: 0.2;
    }

}

.label-tempo {
    font-size: 0.3em;
}
</style>
